import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { getRequestedSettings } from '@/store/features/commonSlice';
import { getSettingByKeyValue, updateImageVersion } from '@/utils/common';
// import Description from './description';

const Description = dynamic(() => import('./description'), { ssr: false });

interface FinestBrandProps {
  data: any;
}
const FinestBrand: React.FC<FinestBrandProps> = ({ data }) => {
  const imageBaseS3Url = process.env.NEXT_PUBLIC_IMAGE_S3_BUCKET_BASE_URL;
  const requestedSettings = useSelector(getRequestedSettings);
  const imgVer = getSettingByKeyValue(requestedSettings, 'config_imgvrsn');
  data.items = updateImageVersion(data?.items, 'v', imgVer);
  return (
    <>
      <section className={styles.finestbrandsection}>
        <div className={styles['finestbrandinner']}>
          <div className="container-fluid">
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.finestbrandheading}>
                  <h2>{data?.title}</h2>
                  <Description description={data?.description} />
                </div>
              </div>
            </div>
            <div className={styles.finestbrandcontent}>
              <div className="wdRow wdgx-lg-4 wdgx-md-3">
                {data?.items?.map((poster: any, index: number) => (
                  <div
                    className="wdCol-lg-4 wdCol-md-4 wdCol-sm-6 wdCol-12"
                    key={index}
                  >
                    <Link
                      href={poster.href}
                      className={styles.finestbrandcard}
                      aria-label={poster.ariaLabel}
                    >
                      <div className={styles.finestbrandimg}>
                        <figure>
                          <Image
                            src={imageBaseS3Url + poster.imgSrc}
                            alt={poster.alt}
                            title={poster.title}
                            width={585}
                            height={508}
                            quality={75}
                            loading="lazy"
                          />
                        </figure>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.seasonsection}>
        <div className={styles.seasoninner}>
          <div className="container-fluid">
            <div className={styles.seasonimg}>
              <Link href="/offline-furniture-store">
                <Image
                  quality={75}
                  loading="lazy"
                  width={1827}
                  height={206}
                  alt=""
                  src={`${imageBaseS3Url}/images/home-new1/strip-furniture.jpg?v=${imgVer}`}
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FinestBrand;
